<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="admin-head">
					<div class="container flex flex-zBetween">
						<div class="admin-le">
							<div class="admin_name">{{$t('nav1')}}</div>
							<div class="admin-tisp flex flex-ac">
								<div class="admin_ti_num whole_yellow flex flex-ac flex-hc">
									{{num}}
								</div>
								<div class="admin_ti_phone">{{set.username}}</div>
								<div class="admin_ti_grade flex flex-ac">
									<i class="iconfont icon-grade"></i>{{$t('acTxt')}}：
									<span
										v-if="set.realVerified==0&&set.phoneVerified==0&&set.fundsVerified==0">{{$t('mTxt7')}}</span>
									<span
										v-else-if="set.realVerified==1&&set.phoneVerified==1&&set.fundsVerified==1">{{$t('mTxt6')}}</span>
									<span v-else>{{$t('mTxt14')}}</span>
								</div>
							</div>
						</div>
						<div class="admin-pic"><img src="../../assets/photo/t1.png" alt=""></div>
					</div>
				</div>
				<div class="admin-box whole_fff">
					<div class="container">
						<div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon1.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt2')}}</div>
									<div v-if="set.realVerified == 1" class="admin_tit_s">{{set.realName}}</div>
									<div v-else-if="set.realVerified==0&&set.realAuditing==0&&set.realNameRejectReason!=null"
										class="admin_tit_s">
										{{$t('acverify')}}{{set.realNameRejectReason?"："+set.realNameRejectReason:""}}，{{$t('again')}}。
									</div>
									<div v-else class="admin_tit_s">
										{{$t('realName')}}
									</div>
								</div>
								<div class="admin_bd">
									<div v-if="set.realVerified==1" class="admin_btn">
										{{$t('acbtn4')}}
									</div>
									<div v-else-if="set.realAuditing==1" class="admin_btn">
										{{$t('acbtn7')}}
									</div>
									<div v-else-if="set.realVerified==0&&set.realAuditing==0&&set.realNameRejectReason!=null"
										class="admin_btn">
										<el-button type="primary" class="btn" @click="realClick">
											{{$t('acbtn8')}}
										</el-button>
									</div>
									<div v-else class="admin_btn">
										<el-button type="primary" class="btn" @click="realClick">
											{{$t('acbtn1')}}
										</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon2.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd" v-if="set.emailVerified == 1">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt3')}}</div>
									<div class="admin_tit_s">{{set.email}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										{{$t('acbtn5')}}
									</div>
								</div>
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd" v-else>
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt3')}}</div>
									<div class="admin_tit_s">{{$t('acDesc3')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										<el-button type="primary" class="btn" @click="bindEmail">{{$t('acbtn2')}}
										</el-button>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon3.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd" v-if="set.phoneVerified == 1">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt4')}}</div>
									<div class="admin_tit_s">{{set.mobilePhone}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										{{$t('acbtn5')}}
									</div>
								</div>
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd" v-else>
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt4')}}</div>
									<div class="admin_tit_s">{{$t('acDesc4')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										<el-button type="primary" class="btn" @click="bindPhone">{{$t('acbtn2')}}
										</el-button>
									</div>
								</div>
							</div>
						</div> -->
						<div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon4.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt5')}}</div>
									<div class="admin_tit_s">{{$t('acDesc5')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										<el-button type="primary" class="btn" @click="modifyLogin">{{$t('acbtn3')}}
										</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon5.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd" v-if="set.fundsVerified == 1">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt6')}}</div>
									<div class="admin_tit_s">{{$t('acDesc6')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										<el-button type="primary" class="btn" @click="modifyFund">{{$t('acbtn3')}}
										</el-button>
									</div>
								</div>
							</div>
							<div v-else class="admin_r flex flex-ac flex-zBetween flex_bd">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt6')}}</div>
									<div class="admin_tit_s">{{$t('acDesc6')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn">
										<el-button type="primary" class="btn" @click="setFund">{{$t('acbtn6')}}
										</el-button>
									</div>
								</div>
							</div>
						</div>
						<div class="admin_flex flex">
							<div class="admin_icon">
								<img src="../../assets/photo/icon6.png" alt="">
							</div>
							<div class="admin_r flex flex-ac flex-zBetween flex_bd">
								<div class="admin_tit flex_bd">
									<div class="admin_tit_n">{{$t('acTxt7')}}</div>
									<div class="admin_tit_s" v-if="set.googleStatus==null || set.googleStatus==0">
										{{$t('acDesc7')}}
									</div>
									<div class="admin_tit_s" v-else>{{$t('acDesc8')}}</div>
								</div>
								<div class="admin_bd">
									<div class="admin_btn" v-if="set.googleStatus==1">
										<el-button type="primary" class="btn" @click="unbindGoogle">{{$t('acDesc9')}}
										</el-button>
									</div>
									<div class="admin_btn" v-else>
										<el-button type="primary" class="btn" @click="bindGoogle">{{$t('acbtn2')}}
										</el-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import {
		securitySet
	} from '@/api/api/user'
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				// user: {},
				set: {},
				num: ''
			};
		},
		mounted() {
			// this.avatar = this.$store.state.user
			this.getAccount()
		},
		methods: {
			getAccount() {
				securitySet().then(res => {
					if (res.code == 0) {
						this.set = res.data
						this.$store.commit('SET_MEMBER', res.data)
						this.num = res.data.username.substring(0, 1)
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			modifyLogin() {
				this.$router.push('/changeLogin')
			},
			modifyFund() {
				this.$router.push('/changeFund')
			},
			bindGoogle() {
				this.$router.push('/changeGoogle')
			},
			bindPhone() {
				this.$router.push('/changePhone')
			},
			bindEmail() {
				this.$router.push('/changeEmail')
			},
			realClick() {
				this.$router.push('/realName')
			},
			setFund() {
				this.$router.push('/setFund')
			},
			unbindGoogle() {
				this.$router.push('/removeGoogle')
			}
		}
	}
</script>
